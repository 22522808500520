import React, { useState,useEffect } from "react";
import { Link as LinkScroll} from 'react-scroll'
import Link from 'next/link';
import { getCookie,hasCookie, setCookie, deleteCookie } from 'cookies-next';
import Image from 'next/image'
import { useRouter } from 'next/router'
import AppContext from '../../AppContext'
import { useContext } from "react";
import * as Icon from 'react-feather';

const Header = ({sticky,isHome}) => {
    const context = useContext(AppContext);

    isHome = (isHome == null)?true:isHome;
    const [menu, setMenu] = React.useState(true)
    const [langActive, setLangActive] = useState({label : 'English', image : 'inggris.jpg'})
    const [openLanguage, setOpenLanguage] = useState(false)
    const router = useRouter();

    const languages = [
        {label: `Indonesia`, value:'/auto/id', image : 'indonesia.png'},
        {label: 'English', value:'/auto/en', image : 'inggris.jpg'},
        {label: `China`, value:'/auto/zh-CN', image : 'china.png'},
    ];

    const navigate = (url) => {
        const urlNav = `${url}/`
        const urlBar = router.asPath
        
        if(urlNav != urlBar) {
            context.setLoading(true)
            document.body.classList.add("overflow-hidden");
            setTimeout(() => {
                router.push(url)
            }, 500);
        }
    }

    const toggleNavbar = () => {
        setMenu(!menu)
    }
    
    async function langChange(value) {
		if(hasCookie('googtrans')){ 
			await new Promise((resolve) => {
				deleteCookie('googtrans', { path: '/', domain: '.thamrin.app' });
				resolve();
			});
		}
		
		if(convertString(getCookie('googtrans')) != convertString(value)) {
            if(hasCookie('googtrans')){
                setCookie('googtrans',decodeURI(value))
            } else {
                setCookie('googtrans',value)
            }
            
            context.setLoading(true)
            document.body.classList.add("overflow-hidden");
            setTimeout(() => {
                router.reload();
            }, 1000);
        }
		
	}

    const googleTranslateElementInit = () => {

        new window.google.translate.TranslateElement({
            pageLanguage: 'auto',
            autoDisplay: false,
            includedLanguages: "ru,en,pl,zh-CN,id",
        },'google_translate_element');
    
        if(document.getElementsByTagName('head')[0] || document.getElementsByTagName('body')[0]) {
            setTimeout(() => {
                context.setLoading(false)
                document.body.classList.remove("overflow-hidden");
            }, 2000);
        }
    }

    useEffect(() => {
        if(hasCookie('googtrans')){
            // setLangActive(convertString(getCookie('googtrans')))

            for (var key in languages) {
                if(convertString(languages[key]['value']) == convertString(getCookie('googtrans'))) {
                    setLangActive({label : languages[key]['label'], image : languages[key]['image']})
                }
            }
        }

        document.body.classList.add("overflow-hidden");
    
        var addScript = document.createElement('script');
        addScript.type = 'text/javascript';
        addScript.async = true;
        addScript.setAttribute('src', '//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit');
        document.body.appendChild(addScript);
        window.googleTranslateElementInit = googleTranslateElementInit;  
    }, [])

    React.useEffect(() => {
        
        let elementId = document.getElementById("header");
        if(!(sticky||false)){
            document.addEventListener("scroll", () => {
                if (window.scrollY > 170) {
                    elementId.classList.add("is-sticky");
                } else {
                    elementId.classList.remove("is-sticky");
                }
            });
        }
        else{
            elementId.classList.add("is-sticky");
        }
        window.scrollTo(0, 0);
    }, [])

    const classOne = menu ? 'collapse navbar-collapse' : 'collapse navbar-collapse show';
    const classTwo = menu ? 'navbar-toggler navbar-toggler-right collapsed' : 'navbar-toggler navbar-toggler-right';

    function convertString(string)
    {
        let result = 'en'

        if(string != undefined) {
            const array = string.split('/')
            result = array[2]
        }
        
        return result;
    }

    return (
        <>
            <header id="header" className="headroom navbar-color-white navbar-style-four">
                <div className="startp-nav nav-group">
                    <div className="header-language">
                        <div className="container">
                            {
                                // languages.map((language, index) => (
                                //     <div key={index} className={`wrap-img flag ${langActive == convertString(language.value) ? 'active' : ''}`} onClick={() => langChange(language.value)} >
                                //         <Image
                                //             alt={language.label}
                                //             src={`/images/bendera/${language.image}`}
                                //             layout='fill'
                                //             objectFit='contain'
                                //         />
                                //     </div>
                                // ))
                            }
                             <div className="flags" onClick={() => setOpenLanguage(!openLanguage)}>
                                <div className={`wrap-img flag`}>
                                    <Image
                                        alt='Bendera'
                                        src={`/images/bendera/${langActive.image}`}
                                        layout='fill'
                                        objectFit='contain'
                                    />
                                </div>
                                <label className="notranslate">{langActive.label}</label>
                                <Icon.ChevronDown size={14} />
                            </div>
                            <div className={`flags-dropdown ${openLanguage ? 'd-block' : 'd-none'}`}>
                                { 
                                    languages.map((language, index) => (
                                        <div key={index} className="menu-flag-dropdown mb-3 notranslate" onClick={() => langChange(language.value)}>
                                            <div className={`wrap-img flag`}>
                                                <Image
                                                    alt={language.label}
                                                    src={`/images/bendera/${language.image}`}
                                                    layout='fill'
                                                    objectFit='contain'
                                                />
                                            </div>
                                            <label>{language.label}</label>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                    <div className="container">

                        <nav className="navbar navbar-expand-md navbar-light">
                            {
                                (isHome)
                                ?(
                                    <LinkScroll to="home" spy={true} smooth={true}>
                                        <div className="wrap-img logo">
                                            <Image
                                                src="/images/TBG-Home/Blue.png"
                                                alt="logo"
                                                layout='fill'
                                                objectFit='contain'
                                            />
                                        </div>
                                    </LinkScroll> 
                                )
                                :(
                                    <a onClick={() => navigate(`/`)} className="nav-link">
                                        <div className="wrap-img logo">
                                            <Image
                                                src="/images/TBG-Home/Blue.png"
                                                alt="logo"
                                                layout='fill'
                                                objectFit='contain'
                                            />
                                        </div>
                                    </a>
                                )
                            }
                            <button
                                onClick={toggleNavbar}
                                className={classTwo}
                                type="button"
                                // data-toggle="collapse"
                                // data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                // aria-expanded="false"
                                // aria-label="Toggle navigation"
                            >
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span>
                            </button>

                            <div className={classOne} id="navbarSupportedContent">
                                <ul className="navbar-nav ms-auto">
                                {
                                    (isHome)
                                    ?(
                                        <>
                                            <li className="nav-item">
                                                <LinkScroll className="nav-link" activeClass='active' to="home" offset={0} spy={true} smooth={true} >
                                                    {
                                                        hasCookie('googtrans') 
                                                        ?   convertString(getCookie('googtrans')) == 'id'
                                                            ?   'Beranda'
                                                            :   'Home'
                                                        :   'Home'
                                                    }
                                                </LinkScroll>
                                            </li>
                                            <li className="nav-item">
                                                <LinkScroll className="nav-link" activeClass='active' offset={20} to="business_partner" spy={true} smooth={true}>
                                                    Our Business
                                                </LinkScroll>
                                            </li>
                                            <li className="nav-item">
                                                <LinkScroll className="nav-link" activeClass='active' offset={20} to="industri" spy={true} smooth={true}>
                                                    Industri We Serve
                                                </LinkScroll>
                                            </li>

                                            <li className="nav-item">
                                                <a href="/careers" className="nav-link" target="_blank">
                                                    Careers
                                                </a>
                                            </li>

                                            <li className="nav-item">
                                                <LinkScroll className="nav-link" activeClass='active' offset={20} to="gallery" spy={true} smooth={true}>
                                                    Gallery
                                                </LinkScroll>
                                            </li>

                                            <li className="nav-item">
                                                <LinkScroll className="nav-link" activeClass='active' offset={20} to="subscribe" spy={true} smooth={true}>
                                                    Subscribe
                                                </LinkScroll>
                                            </li>

                                            {/* <li className="nav-item">
                                                <LinkScroll className="nav-link" activeClass='active' offset={20} to="carrers" spy={true} smooth={true}>
                                                    Careers
                                                </LinkScroll>
                                            </li> */}

                                        </>
                                    )
                                    :(
                                        <>
                                            <li className="nav-item">
                                                <a onClick={() => navigate(`/?s=home`)} className="nav-link">
                                                    {
                                                        hasCookie('googtrans') 
                                                        ?   convertString(getCookie('googtrans')) == 'id'
                                                            ?   'Beranda'
                                                            :   'Home'
                                                        :   'Home'
                                                    }
                                                </a>
                                            </li>

                                            <li className="nav-item">
                                                <a onClick={() => navigate(`/?s=business_partner`)} className="nav-link">
                                                    Our Business
                                                </a>
                                            </li>

                                            <li className="nav-item">
                                                <a onClick={() => navigate(`/?s=industri`)} className="nav-link">
                                                    Industri We Serve
                                                </a>
                                            </li>

                                            <li className="nav-item">
                                                <Link href="/careers">
                                                    Careers
                                                </Link>
                                            </li>

                                            <li className="nav-item">
                                                <a onClick={() => navigate(`/?s=gallery`)} className="nav-link">
                                                    Gallery
                                                </a>
                                            </li>

                                            <li className="nav-item">
                                                <a onClick={() => navigate(`/?s=subscribe`)} className="nav-link">
                                                    Subscribe
                                                </a>
                                            </li>
                                        </>
                                    )
                                }
                                </ul>
                            </div>
                        </nav>

                    </div>
                </div>
            </header>
            
        </>
    );
}

export default Header;